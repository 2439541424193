import {Component, Input, OnInit} from '@angular/core';
import {ReviewerService} from '@core/service/reviewer.service';
import {Reviewer} from '@core/model/reviewer';
import {IncomeHistory} from "@core/model/wallet";

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit {
  @Input()
  reviewer?: Reviewer;
  @Input()
  yesterdayIncome: string = '';
  @Input()
  todayIncome: string = '';
  @Input()
  balance: string = '';


  numberFormat = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KZT'
  });

  constructor(private reviewerService: ReviewerService) {
  }

  ngOnInit(): void {
    this.reviewerService.getReviewerInfo()
    .subscribe(value => {
      this.reviewer = value;
      this.balance = this.numberFormat.format(this.reviewer.wallet.balance);
      if (value.incomeHistory && value.incomeHistory.length > 0 && value.incomeHistory.length <= 2) {
        value.incomeHistory.forEach((history) => {
          this.setIncome(history);
        });
      }

      if (this.yesterdayIncome == '') {
        this.yesterdayIncome = this.numberFormat.format(0);
      }
      if (this.todayIncome == '') {
        this.todayIncome = this.numberFormat.format(0);
      }
    });
  }

  setIncome(history: IncomeHistory): void {
    let now = new Date();
    const monthStr = this.getNumberStr(now.getMonth() + 1, 2)
    const dayStr = this.getNumberStr(now.getDate(), 2)
    let nowString = `${now.getFullYear()}-${monthStr}-${dayStr}`

    console.log(`${nowString} ${history.date}`)

    if (history.date == nowString) {
      this.todayIncome = this.numberFormat.format(history.income);
    } else {
      this.yesterdayIncome = this.numberFormat.format(history.income);
    }
  }

  getNumberStr(n: number, digits: number): string {
    return n.toLocaleString('en-US', {
      minimumIntegerDigits: digits,
      useGrouping: false
    })
  }
}
