import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReviewDetail} from '@core/model/review';
import {ReviewerService} from "@core/service/reviewer.service";
import {Category} from "@core/model/category";
import {CategoryService} from "@core/service/category.service";
import {getStatusName} from "@core/model/enums/review-status";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "environments/environment";
import {IncomeHistory} from "@core/model/wallet";
import {VisitsHistory} from "@core/model/metric";

@Component({
  selector: 'app-user-review-modification-page',
  templateUrl: './user-review-modification-page.component.html',
  styleUrls: ['./user-review-modification-page.component.css']
})
export class UserReviewModificationPageComponent {
  reviewDetail?: ReviewDetail;
  form: FormGroup;

  categoryList: Category[] = [];

  reviewContent: string = '';

  @Input()
  topicImageUrls: Map<number, string> = new Map();
  @Input()
  reviewImageUrls: Map<number, string> = new Map();

  getBasePhotoUrl = environment.basePhotoUrl;

  currencyFormat = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KZT'
  });

  numberFormat = Intl.NumberFormat('en-US', {
  });

  @Input()
  yesterdayIncome: string = '';
  @Input()
  todayIncome: string = '';
  @Input()
  income: string = '';

  @Input()
  yesterdayVisits: string = '';
  @Input()
  todayVisits: string = '';
  @Input()
  totalVisits: string = '';

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private reviewerService: ReviewerService,
              private categoryService: CategoryService,
              private sanitizer: DomSanitizer) {
    this.form = this.fb.group({
      reviewTopicTitle: new FormControl('', [Validators.required]),
      reviewTopicCategory: new FormControl('', [Validators.required]),
      reviewTopicDescription: new FormControl('', [Validators.required]),
      reviewTitle: new FormControl('', [Validators.required]),
      reviewShortDescription: new FormControl('', [Validators.required]),
      reviewContent: new FormControl('', [Validators.required, Validators.minLength(500)]),
    });

    const reviewId = this.route.snapshot.paramMap.get('reviewId');
    if (reviewId) {
      this.categoryService.getCatalog().subscribe(catalog => {
        this.categoryList = this.categoryService.catalogToCategories(catalog);
        this.reviewerService.getReviewById(parseInt(reviewId, 10))
        .subscribe(value => {
          this.reviewDetail = value;

          this.form.get('reviewTopicTitle')?.setValue(this.reviewDetail.reviewTopic?.name);
          this.form.get('reviewTopicCategory')?.setValue(this.reviewDetail.reviewTopic?.category.id);
          this.form.get('reviewTopicDescription')?.setValue(this.reviewDetail.reviewTopic?.description);

          this.form.get('reviewTitle')?.setValue(this.reviewDetail.title);
          this.form.get('reviewShortDescription')?.setValue(this.reviewDetail.shortDescription);
          if (this.reviewDetail?.blocks && this.reviewDetail?.blocks.length > 0) {
            this.reviewContent = this.reviewDetail?.blocks[0].text ?? '';
          }

          this.reviewDetail?.media?.forEach((value, index) => {
            this.reviewImageUrls.set(index + 1, value.largeImage);
          });

          this.reviewDetail?.reviewTopic?.media?.forEach((value, index) => {
            this.topicImageUrls.set(index + 1, value.largeImage);
          });
          this.form.disable();
          this.form.get('reviewContent')?.disable();

          this.income = this.currencyFormat.format(value.totalIncome.income);
          if (value.incomeHistory && value.incomeHistory.length > 0 && value.incomeHistory.length <= 2) {
            value.incomeHistory.forEach((history) => {
              this.setIncome(history);
            });
          }

          if (this.yesterdayIncome == '') {
            this.yesterdayIncome = this.currencyFormat.format(0);
          }
          if (this.todayIncome == '') {
            this.todayIncome = this.currencyFormat.format(0);
          }

          this.totalVisits = this.numberFormat.format(value.totalVisits);
          if (value.visitsHistory && value.visitsHistory.length > 0 && value.visitsHistory.length <= 2) {
            value.visitsHistory.forEach((history) => {
              this.setVisits(history);
            })
          }

          if (this.yesterdayVisits == '') {
            this.yesterdayVisits = this.numberFormat.format(0);
          }
          if (this.todayVisits == '') {
            this.todayVisits = this.numberFormat.format(0);
          }

        });
      });
    }
  }

  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setVisits(history: VisitsHistory): void {
    if (this.isToday(history.date)) {
      this.todayVisits = this.numberFormat.format(history.visits);
    } else {
      this.yesterdayVisits = this.numberFormat.format(history.visits);
    }
  }

  setIncome(history: IncomeHistory): void {
    if (this.isToday(history.date)) {
      this.todayIncome = this.currencyFormat.format(history.income);
    } else {
      this.yesterdayIncome = this.currencyFormat.format(history.income);
    }
  }

  getNumberStr(n: number, digits: number): string {
    return n.toLocaleString('en-US', {
      minimumIntegerDigits: digits,
      useGrouping: false
    })
  }

  isToday(date: string): boolean {
    let now = new Date();
    const monthStr = this.getNumberStr(now.getMonth() + 1, 2)
    const dayStr = this.getNumberStr(now.getDate(), 2)
    let nowString = `${now.getFullYear()}-${monthStr}-${dayStr}`

    return date == nowString;
  }


  protected readonly getStatusName = getStatusName;
}
